(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("phonenumber", function (value) {
        var phonenumberregex = /^(?:^[0-9(+](?:(?!--|\ \ |\(\(|\)\)|\+\+|\.\.|\,\,)[\-0-9()+.,\ ])*[e]?[x]?[t]?[.]?[#]?[*]?[\ ]?[0-9]*$)$/i;

        if (value === "") {
            return true;
        }
        return phonenumberregex.test(value);
    });

    $.validator.unobtrusive.adapters.addBool("phonenumber");

}(jQuery));