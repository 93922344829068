(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("enforcechecked", function (value, element) {
        return $(element).is(":checked");
    });

    $.validator.unobtrusive.adapters.add("enforcechecked", [], function (options) {
        options.rules.enforcechecked = options.params;

        if (options.message) {
            options.messages.enforcechecked = options.message;
        }
    });

}(jQuery));