(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("multilinetext", function (value) {
        var multilinetextregex = /^[\-a-z0-9`~!@#$%\^&*()_=+;:"“”'’,.\/?\s]*$/i;
        return multilinetextregex.test(value);
    });

    $.validator.unobtrusive.adapters.addBool("multilinetext");

}(jQuery));