(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("smokingstatusid", function (value, element) {
        return $(element).val() === "1" || $(element).val() === "2";
    });

    $.validator.unobtrusive.adapters.addBool("smokingstatusid");

}(jQuery));