(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("date", function (value) {
        if (value !== "") {
            var dateregex = /^(?:(?![0-9][0-9]\/[0-9][0-9][0-9])(?:(?![0-9][0-9][0-9])[0-9](?:(?![0-9][0-9])[0-9]?))(?:(?:(?!\/\/)\/?)(?:(?![0-9][0-9][0-9]\/)[0-9]?[0-9]?))(?:(?:(?!\/\/)\/?))(?:(?![0-9][0-9][0-9][0-9][0-9])[0-9]?[0-9]?[0-9]?[0-9]?))$/i;
            return dateregex.test(value);
        }
        return true;
    });

    $.validator.unobtrusive.adapters.addBool("date");

}(jQuery));