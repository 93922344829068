(function ($) {
    "use strict";

    // Helpers
    // =======

    function getElementNamePrefix(elementname) {
        var nameprefix = "";

        if (elementname.lastIndexOf(".") !== -1) {
            nameprefix = elementname.slice(0, elementname.lastIndexOf(".")) + ".";
        }
        return nameprefix;
    }

    function getElementIdPrefix(nameprefix) {
        return nameprefix.replace(/\./g, "_");
    }

    // Validator
    // =========

    $.validator.addMethod("noneoftheaboverequired", function (value, element, params) {
        if ($(element).prop("checked") === false) {
            var isrequired = true;

            var nameprefix = getElementNamePrefix(element.name);
            var idprefix = getElementIdPrefix(nameprefix);

            var otherpropertiesarray = params.otherproperties.split(",");

            $.each(otherpropertiesarray, function (index, value) {
                var otherproperty = $("#" + idprefix + value);

                if (otherproperty.prop("checked")) {
                    isrequired = false;
                }
            });

            if (isrequired) {
                return false;
            }
        }
        return true;
    });

    $.validator.unobtrusive.adapters.add("noneoftheaboverequired", ["otherproperties", "value"], function (options) {
        options.rules.noneoftheaboverequired = {
            otherproperties: options.params.otherproperties,
            value: options.params.value
        };
        options.messages.noneoftheaboverequired = options.message;
    });

}(jQuery));