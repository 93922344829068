(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("yesno", function (value, element) {
        return $(element).val() === "Yes" || $(element).val() === "No";
    });

    $.validator.unobtrusive.adapters.addBool("yesno");

}(jQuery));