(function ($) {
    "use strict";

    // Helpers
    // =======

    function floatValue(value) {
        return parseFloat(value.replace(/,/g, ""));
    }

    // Validator
    // =========

    $.validator.methods.number = function (value, element) {
        value = floatValue(value);
        return this.optional(element) || !isNaN(value);
    };

}(jQuery));