(function ($) {
    "use strict";

    // Helpers
    // =======

    function getPropertyValue(propertyid) {
        var propertyname = propertyid.replace("_", ".");
        var property = $("input[name='" + propertyid + "']");

        if (property.is("input:radio")) {
            return $("input:radio[name='" + propertyname + "']:checked").val();
        }
        return $("#" + propertyid).val();
    }

    // Validator
    // =========

    $.validator.addMethod("enforcecheckedif", function (value, element, params) {

        if ($(element).is(":checked")) {
            return true;
        }

        // Get the other property value.
        var otherpropertyvalue = getPropertyValue(params.otherproperty);

        // Check if the other property meets the requiredif condition.
        if (otherpropertyvalue.toString().toLowerCase() !== params.otherpropertyvalue.toString().toLowerCase()) {
            return true;
        }

        return false;
    });

    $.validator.unobtrusive.adapters.add("enforcecheckedif", ["otherproperty", "otherpropertyvalue"], function (options) {
        options.rules.enforcecheckedif = {
            otherproperty: options.params.otherproperty,
            otherpropertyvalue: options.params.otherpropertyvalue
        };
        options.messages.enforcecheckedif = options.message;
    });

}(jQuery));