(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("title", function (value) {
        var titleregex = /^(Mr|Mrs|Miss|Ms|Dr|Other)*$/i;
        return titleregex.test(value);
    });

    $.validator.unobtrusive.adapters.addBool("title");

}(jQuery));