// Modifies existing range validator.

(function ($) {
    "use strict";

    // Helpers
    // =======

    function floatValue(value) {
        return parseFloat(value.replace(/,/g, ""));
    }

    // Validator
    // =========

    $.validator.methods.range = function (value, element, params) {
        value = floatValue(value);
        return this.optional(element) || (value >= params[0] && value <= params[1]);
    };

}(jQuery));