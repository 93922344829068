(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("quizanswer", function (value) {
        var quizanswerregex = /^(a|b|c|d)*$/i;
        return quizanswerregex.test(value);
    });

    $.validator.unobtrusive.adapters.addBool("quizanswer");

}(jQuery));