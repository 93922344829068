(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("genderid", function (value, element) {

        if (value === "") {
            return true;
        }
        
        return $(element).val() === "1" || $(element).val() === "2";
    });

    $.validator.unobtrusive.adapters.addBool("genderid");

}(jQuery));