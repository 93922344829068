(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("singlelinetext", function (value) {
        var singlelinetextregex = /^[\-a-z0-9`~!@#$%\^&*()_=+;:"“”'’,.\/?\ ]*$/i;
        return singlelinetextregex.test(value);
    });

    $.validator.unobtrusive.adapters.addBool("singlelinetext");

}(jQuery));