(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("personname", function (value) {
        const personnameregex = /^[\-a-z0-9()'’,.\ ]*$/i;
        return personnameregex.test(value);
    });

    $.validator.unobtrusive.adapters.addBool("personname");

}(jQuery));