(function ($) {
    "use strict";

    // Validatior
    // ==========

    $.validator.addMethod("lifeinsuredid", function (value) {
        var insuredidregex = /^(Life|Child)[1-9]{1}$/;
        return insuredidregex.test(value);
    });

    $.validator.unobtrusive.adapters.addBool("lifeinsuredid");

}(jQuery));