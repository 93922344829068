(function ($) {
    "use strict";

    // Validator
    // =========

    $.validator.addMethod("promocode", function () {
        // Always return true.
        // Validated server-side only.
        return true;
    });

    $.validator.unobtrusive.adapters.addBool("promocode");

}(jQuery));